import './App.css';
import IndexLacBauCua from './container';

function App() {
  return (
    <div className="lac-bau-cua">
      <IndexLacBauCua/>
    </div>
  );
}

export default App;
